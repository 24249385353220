<template>
  <section>
    <div class="overlay" />
    <div class="popup">
      <Header type="h3" spaced class="popup__header">
        <template v-slot:title>{{ title }}</template>
        <template v-slot:addons>
          <span @click="close" class="popup__header-close">
            <icon-base icon-name="cross" />
          </span>
        </template>
      </Header>

      <div class="popup__body">
        <slot />
      </div>
      <div class="popup__footer">
        <Button label="Сохранить" @click="approve" />
      </div>
    </div>
  </section>
</template>

<script>
import Header from "./Header.vue";
import Button from "./Form/Button.vue";
import IconBase from "./Icons/IconBase.vue";

export default {
  name: "Popup",
  components: { Header, Button, IconBase },
  props: {
    title: {
      type: String,
      default: "Попап",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    approve() {
      this.$emit("approve");
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  unmounted() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  z-index: $popup-overlay-z-index;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $popup-overlay-color;
  opacity: 0.6;
}

.popup {
  z-index: $popup-content-z-index;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  width: $popup-content-width;
  background: $popup-content-background-color;
  box-shadow: 0px 20px 65px #d0d8e13d, 0px 4px 15px #d0d8e147,
    0px 2px 6px #d0d8e129;
  border-radius: $border-radius-md;
  opacity: 1;

  &__header {
    padding: $space-xl;

    &-close {
      cursor: pointer;
    }
  }

  &__body {
    padding: 0px $space-xl $space-xl;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    justify-content: end;
    padding: $space-xmd $space-xl;
    border-top: 1px solid $popup-footer-border-color;
    text-align: right;
  }
}
</style>
