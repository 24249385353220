<template>
  <Popup :title="title">
    <FormList v-model="value" :list="list" />
  </Popup>
</template>

<script>
import types from "../../config/types.js";
import Popup from "../../components/Popup.vue";
import FormList from "../../components/Form/List.vue";

export default {
  name: "Server",
  components: { Popup, FormList },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: types.serverModes.add,
    },
  },
  computed: {
    title() {
      if (this.mode === types.serverModes.add) return "Добавить сервер";
      if (this.mode === types.serverModes.edit) return "Редактировать сервер";
      return "Сервер";
    },
    value: {
      get() {
        return { ...this.modelValue };
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    list() {
      return [
        {
          id: "name",
          label: "Имя сервера (хост)",
          type: types.components.input,
          required: true,
          width: 12,
          attr: {
            placeholder: "sip.myserver.com",
          },
        },
        {
          id: "nat",
          label: "NAT",
          type: types.components.input,
          width: 12,
          attr: {
            placeholder: "NAT",
          },
        },
        {
          id: "dtmfmode",
          label: "DTMF",
          type: types.components.input,
          width: 12,
          attr: {
            placeholder: "DTMF",
          },
        },
        {
          id: "transport",
          label: "Транспорт",
          type: types.components.select,
          width: 12,
          attr: {
            placeholder: "Транспорт",
            options: [
              {id: 'udp', label: 'udp'},
              {id: 'tcp', label: 'tcp'},
              {id: 'tls', label: 'tls'},
            ]
          },
        },
        {
          id: "type",
          label: "Тип",
          type: types.components.select,
          width: 12,
          attr: {
            placeholder: "Тип",
            options: [
              {id: 'basip', label: 'BasIP'},
              {id: 'custom', label: 'Пользовательский'},
            ]
          },
        },
        // {
        //   id: "quality",
        //   label: "Качество",
        //   type: types.components.input,
        //   width: 12,
        //   attr: {
        //     placeholder: "Качество",
        //   },
        // },
        // {
        //   id: "fromdomain",
        //   label: "Fromdomain",
        //   type: types.components.input,
        //   width: 12,
        //   attr: {
        //     placeholder: "Fromdomain",
        //   },
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
