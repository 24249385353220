// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ex[data-v-7e72e2f4] {
  margin: 0px 24px;
  width: 100%;
}
.ex[data-v-7e72e2f4] * {
  margin: 24px 0px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/Components.vue","webpack://./Components.vue"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,WAAA;ACDF;ADGE;EACE,gBAAA;ACDJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.ex {\n  margin: 0px $space-lg;\n  width: 100%;\n\n  * {\n    margin: $space-lg 0px;\n  }\n}\n",".ex {\n  margin: 0px 24px;\n  width: 100%;\n}\n.ex * {\n  margin: 24px 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
