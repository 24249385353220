<template>
  <div class="main">
    <Notifications />
    <router-view></router-view>
  </div>
</template>

<script>
import Notifications from "../components/Notifications.vue";
import bridge from '@open-condo/bridge';

export default {
  name: "App",
  components: { Notifications },
  data() {
    return {};
  },
  mounted() {
    const observer = new ResizeObserver((entries) => {
        if (entries && entries.length) {
            bridge.send('CondoWebAppResizeWindow', { height: entries[0].target.clientHeight + 100 })
        }
    })

    observer.observe(document.body)
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
body {
  margin: 0px;
  padding: 0px;
}

* {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: $font-size-lg;
  font-weight: $font-weight-md;
}

.main {
  position: relative;
  margin: $space-xxl;
}
</style>
