<template>
  <form enctype="multipart/form-data" class="form">
    <div v-if="fileName" class="form__file">
      <icon-base
        icon-name="attachment"
        :label="fileName"
        class="form__file-label"
        @click="$refs.inputFile.click()"
      />
      <icon-base
        icon-name="trash"
        label="Удалить"
        class="form__file-remove"
        @click="onFileRemove"
      />
    </div>
    <div v-else class="form__file">
      <icon-base
        icon-name="upload-file"
        class="form__file-label"
        :label="label"
        @click="$refs.inputFile.click()"
      />
    </div>

    <input
      type="file"
      ref="inputFile"
      class="form__input"
      @change="onFileChange"
    />
  </form>
</template>

<script>
import IconBase from "../Icons/IconBase.vue";

export default {
  name: "File",
  components: { IconBase },
  props: {
    modelValue: {
      type: File,
      default: null,
    },
    label: {
      type: String,
      default: "Загрузить файл",
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      file && this.$emit("update:modelValue", file);
    },
    onFileRemove() {
      this.$emit("update:modelValue", null);
    },
  },
  computed: {
    fileName() {
      return this.modelValue?.name || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  $root: &;

  display: inline-block;

  &__file {
    display: flex;
    align-items: center;
    gap: $space-lg;

    &-label {
      display: flex;
      align-items: center;
      gap: $space-xxxxs;
      cursor: pointer;
    }

    &-remove {
      display: none;
      cursor: pointer;
      color: $color-red;
    }

    &:hover {
      #{$root}__file-remove {
        display: flex;
      }
    }
  }

  &__input {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
}
</style>
