import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData],
  data() {
    return {
      editValue: {
        address: null,
        server: null,
      },
      currentIntercom: null
    };
  },
  methods: {
    onSaveAddress() {
      this.intercomService
        .updateIntercom(this.$route.params.intercomId, {
          puid: this.editValue.address,
          suid: this.editValue.server,
        })
        .then((response) => {
          console.log(response);
          this.$router.push({
            name: "Address",
            params: { intercomId: this.$route.params.intercomId },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onServerPopupApprove() {
      let data = this.serverPopup.modelValue;

      this.serverService
        .updateServer(this.editValue.server, {
          ouid: this.currentOrganizationId,
          host: data.name,
          fromdomain: data.fromdomain,
          nat: data.nat,
          dtmfmode: data.dtmfmode,
          quality: data.quality,
          transport: data.transport,
          type: data.type,
        })
        .then((response) => {
          if (response.data) {
            this.initServers();
          }

          this.serverPopup.opened = false;
        })
        .catch((error) => {
          console.log(error);
          this.serverPopup.opened = false;
        });
    },
    async initServers() {
      await this.intercomService.getIntercom(this.$route.params.intercomId).then(response => {
        if (response.data) {
            this.currentIntercom = response.data
        }
      }).catch((error) => {
          console.log(error)
      })

      this.editValue.server = this.currentServer.suid ?? null;
      this.editValue.address = this.currentProperty.puid ?? null;
    },
  },
  async mounted() {
    await this.initServers();

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }
  },
  computed: {
    editElementsSettings() {
      return {
        address: {
          label: "Адрес",
          required: true,
          type: types.components.select,
          attr: {
            placeholder: "Адрес",
            mode: "single",
            options: this.propertyListOptions,
          },
        },
        server: {
          label: "Сервер",
          required: true,
          type: types.components.select,
          attr: {
            placeholder: "Сервер",
            mode: "single",
            options: this.serverListOptions,
          },
        },
      };
    },
    server() {
      return {
        name: this.selectedServer.host ?? "",
        nat: this.selectedServer.nat ?? "",
        dtmfmode: this.selectedServer.dtmfmode ?? "",
        transport: this.selectedServer.transport ?? "",
        type: this.selectedServer.type ?? "",
        quality: this.selectedServer.quality ?? "",
        fromdomain: this.selectedServer.fromdomain ?? "",
      };
    },
    selectedServer() {
      return (
        this.serverList.filter(
          (server) => server.suid === this.editValue.server
        )[0] ?? {}
      );
    },
    currentProperty() {
      return this.currentIntercom && this.currentIntercom.property ? this.currentIntercom.property : null;
    },
    currentServer() {
      return this.currentIntercom && this.currentIntercom.sip_server ? this.currentIntercom.sip_server : null;
    },
  },
};
