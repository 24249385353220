import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      filtersData: {}
    }
  },
  computed: {
    srvr() {
      return {
        name: this.currentServer ? this.currentServer.host : 'No name',
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
        ],
        count: 3,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "address",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Адреса",
            options: this.propertyListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "unit_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "sip_id",
          label: "Extension",
          onclick: () => {
            this.filtersData.order = 'extension';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension'
        },
        {
          id: "sip_id_udp",
          label: "Extension UDP",
          onclick: () => {
            this.filtersData.order = 'extension_udp';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension_udp'
        },
        {
          id: "password",
          label: "Пароль",
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sip_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "sip_id",
              content: {
                id: contact.uid,
                label: contact.extension,
              },
            },
            {
              id: "unit_type",
              content: {
                id: contact.uid,
                label: contact.unit_type && contact.unit_type == 'flat' ? 'Квартира' : 'Апартаменты',
              },
            },
            {
              id: "sip_id_udp",
              content: {
                id: contact.uid,
                label: contact.extension_udp,
              },
            },
            {
              id: "password",
              content: {
                id: contact.uid,
                label: contact.password,
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Редактировать сервер",
              icon: "edit",
              handler: () => this.onEditServer(),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить cервер",
              icon: "trash",
              handler: () => this.onDeleteServer(this.$route.params.serverId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Верифицировать абонентов",
              icon: "check",
              handler: () => this.verifySelectedContacts(),
            },
          },
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить строку",
              icon: "trash",
              handler: () => this.deleteContacts(),
            },
          },
        ],
      };
    },
    currentServer() {
      return this.serverList.filter(server => server.suid === this.$route.params.serverId)[0] ?? null
    },
    server() {
      return {
        name: this.currentServer.host,
        nat: this.currentServer.nat,
        dtmfmode: this.currentServer.dtmfmode,
        transport: this.currentServer.transport,
        type: this.currentServer.type,
        quality: this.currentServer.quality,
        fromdomain: this.currentServer.fromdomain,
      };
    },
  },
  mounted() {

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      sipServerId: this.$route.params.serverId
    });

    this.getContacts({
      organizationId: this.currentOrganizationId,
      sipServerId: this.$route.params.serverId
    }).then(response => {
      this.setContactsPagination();
    });
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    getFilteredContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sipServerId = this.$route.params.serverId;

      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
      
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sipServerId = this.$route.params.serverId;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row)
    },
    verifyContact(contactId) {
      this.contactService.verifyContacts({contacts: [{id: contactId}]}).then(response => {
        this.getFilteredContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    verifySelectedContacts() {
      let data = {contacts: []}
      this.rowsSelected.forEach(value => {
        data.contacts.push({id: value})
      })
      this.contactService.verifyContacts(data).then(response => {
        this.rowsSelected = [];
        this.getFilteredContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    deleteContacts() {
      this.contactService.removeContacts(this.rowsSelected).then(response => {
        console.log(response);
        this.getFilteredContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page,
        this.contactsMeta.total,
        this.contactsMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
    onServerPopupApprove() {
      this.serverService.updateServer(this.$route.params.serverId, {
        ouid: this.currentOrganizationId,
        host: this.serverPopup.modelValue.name,
        // fromdomain: this.serverPopup.modelValue.fromdomain,
        nat: this.serverPopup.modelValue.nat,
        dtmfmode: this.serverPopup.modelValue.dtmfmode,
        // quality: this.serverPopup.modelValue.quality,
        transport: this.serverPopup.modelValue.transport,
        type: this.serverPopup.modelValue.type
      }).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.initPagination(
            this.serversMeta.current_page,
            this.serversMeta.total,
            this.serversMeta.per_page
          );
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        console.log(error)
        this.serverPopup.opened = false;
      });
    },
  }
};
