<template>
  <section class="add">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />
    <Header class="add__header">
      <template v-slot:title>Настроить связку SIP-сервера с адресом</template>
    </Header>
    <p class="add__message">
      Выгрузить из BasIP файлы с абонентами и загрузите сюда (справка по
      формату файла). <br />
      Можно добавить один файл по каждому адресу.
    </p>

    <div v-for="(address, index) in addressList" :key="address.id">
      <Address
        v-model:form="address.form"
        v-model:file="address.file"
        :index="index + 1"
        :removable="index > 0"
        :formList="formList"
        @remove="removeAddress(address.id)"
      />
    </div>

    <div class="add__actions">
      <Actions :actions="actions.default" />
    </div>
  </section>
</template>

<script>
import Header from "../../components/Header.vue";
import Actions from "../../components/Actions.vue";
import Address from "./Address.vue";
import ServerPopup from "../Popup/Server.vue";
import MockServer from "../../mixins/Mock/MockServer.js";
import MockAddIntercom from "../../mixins/Mock/MockAddIntercom.js";

export default {
  name: "AddIntercom",
  mixins: [MockServer, MockAddIntercom],
  components: { Header, ServerPopup, Actions, Address },
};
</script>

<style lang="scss" scoped>
.add {
  width: 100%;

  &__header {
    margin: 0px $space-md $space-xxl;
  }

  &__message {
    margin: 0px $space-md $space-md;
  }
}
</style>
