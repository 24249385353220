export default {
    state: {
        intercoms: [],
        tempIntercomIds: [],
        intercomsMeta: {},
    },
    getters: {
        intercoms: state => state.intercoms,
        tempIntercomIds: state => state.tempIntercomIds.length > 0 ? state.tempIntercomIds : JSON.parse(localStorage.getItem('tempIntercomIds')),
        intercomsMeta: state => state.intercomsMeta
    },
    mutations: {
        SET_INTERCOMS(state, payload) {
            state.intercoms = payload;
        },
        ADD_NEW_INTERCOM(state, payload) {
            state.intercoms.push(payload);
        },
        REMOVE_INTERCOMS(state, payload) {
            state.intercoms = state.intercoms.filter(intercom => !payload.includes(intercom.cuid));
        },
        SET_TEMP_INTERCOM_IDS(state, payload) {
            state.tempIntercomIds.push(payload);
            localStorage.setItem('tempIntercomIds', JSON.stringify(payload))
        },
        DELETE_TEMP_INTERCOM_IDS(state) {
            state.tempIntercomIds = [];
            localStorage.removeItem('tempIntercomIds');
        },
        SET_INTERCOMS_META(state, payload) {
            state.intercomsMeta = payload;
        }
    },
    actions: {
        setIntercoms({commit}, payload) {
            commit('SET_INTERCOMS', payload);
        },
        addNewIntercom({commit}, payload) {
            commit('ADD_NEW_INTERCOM', payload);
        },
        removeIntercoms({commit}, cuid) {
            commit('REMOVE_INTERCOMS', cuid);
        },
        setTempIntercomIds({commit}, payload) {
            commit('SET_TEMP_INTERCOM_IDS', payload);
        },
        deleteTempIntercomIds({commit}) {
            commit('DELETE_TEMP_INTERCOM_IDS');
        },
        setIntercomsMeta({commit}, payload) {
            commit('SET_INTERCOMS_META', payload);
        }
    }
}