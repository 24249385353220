<template>
  <section class="address">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />
    <Header class="address__header" >
      <template v-slot:title>Сервер {{ srvr.name }}</template>
      <template v-slot:addons>
        <Chips :chips="srvr.chips" />
      </template>
    </Header>
    
    <BackButton router-page-name="Intercom" class="address__back">
      Назад к домофонам
    </BackButton>
    <Header class="address__subheader" type="h4">
      <template v-slot:title>
        Адреса
      </template>
    </Header>
    <div class="address__filters">
      <Filters v-model="filtersActive" :filters="filters" />
    </div>
    <div class="address__list">
      <Table
        v-model="rowsSelected"
        :columns="tableColumns"
        :rows="tableRows"
        @switcherClick="onSwitcherClick"
      />
      <Pagination v-model="currentPage" v-bind="pagination" @switchPage="onSwitchPage" />
    </div>
    <div class="address__actions">
      <Actions
      :actions="rowsSelected.length ? actions.selected : actions.default"
      />
    </div>
  </section>
</template>

<script>
import Header from "../../components/Header.vue";
import Filters from "../../components/Filters.vue";
import Table from "../../components/Table.vue";
import Actions from "../../components/Actions.vue";
import MockIntercomServer from "../../mixins/Mock/MockIntercomServer.js";
import IconBase from "../../components/Icons/IconBase.vue";
import Chips from "../../components/Chips.vue";
import ServerPopup from "../Popup/Server.vue";
import MockPagination from "../../mixins/Mock/MockPagination.js";
import Pagination from "../../components/Pagination.vue";
import BackButton from "../../components/BackButton.vue";

export default {
  name: "AddressList",
  mixins: [MockIntercomServer, MockPagination],
  components: { Header, Filters, Table, Actions, IconBase, Chips, ServerPopup, Pagination, BackButton },
  data() {
    return {
      filtersActive: {},
      rowsSelected: [],
    };
  },
  watch: {
    filtersActive: {
      handler(value) {
        if (value.status === null) {
          value.status = '';
        }
        
        this.filterContacts(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  width: 100%;

  &__header,
  &__filters,
  &__back,
  &__list {
    margin: 0px $space-md $space-lg;
  }

  &__subheader {
    margin: $space-xl $space-md $space-lg;
  }
}
</style>
