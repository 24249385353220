import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      filePopup: {
        file: null,
        opened: false,
      },
      clientPopup: {
        list: [
          {
            id: "extension",
            label: "Sip id",
            required: true,
            type: types.components.input,
            width: 12,
            attr: {
              placeholder: "Sip id",
            },
          },
          {
            id: "password",
            label: "Пароль",
            required: true,
            type: types.components.input,
            width: 12,
            attr: {
              placeholder: "Пароль",
            },
          },
        ],
        value: {},
        opened: false,
      },
      searchTimeOut: null,
      currentIntercom: null,
      filtersData: {}
    };
  },
  computed: {
    address() {
      return {
        address: this.currentAddress,
        ip: this.currentServer ? this.currentServer.host : null,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
        ],
        count: this.contactList.length ?? 0,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "status",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Статус",
            options: [
              {
                id: true,
                label: "Верифицирован",
              },
              {
                id: false,
                label: "Не верифицирован",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "unit_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "sip_id",
          label: "Extension",
          onclick: () => {
            this.filtersData.order = 'extension';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension'
        },
        {
          id: "sip_id_udp",
          label: "Extension UDP",
          onclick: () => {
            this.filtersData.order = 'extension_udp';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension_udp'
        },
        {
          id: "password",
          label: "Пароль",
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status'
        },
        {
          id: "isNotifiable",
          component: {
            type: "switcher",
            "label-true": "UDP",
            "label-false": "WebRTC",
          },
          label: "Протокол",
          onclick: () => {
            this.filtersData.order = 'isNotifiable';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getCurrentInetrcomContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'isNotifiable'
        },
      ];
    },
    tableRows() {
      const data = [];
      
      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sip_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "unit_type",
              content: {
                id: contact.uid,
                label: contact.unit_type && contact.unit_type == 'flat' ? 'Квартира' : 'Апартаменты',
              },
            },
            {
              id: "sip_id",
              content: {
                id: contact.uid,
                label: contact.extension,
              },
            },
            {
              id: "sip_id_udp",
              content: {
                id: contact.uid,
                label: contact.extension_udp,
              },
            },
            {
              id: "password",
              content: {
                id: contact.uid,
                label: contact.password,
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
            {
              id: "isNotifiable",
              content: {
                id: contact.uid,
                label: contact.is_notifiable,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Редактировать адрес или сервер",
              icon: "edit",
              handler: () =>
                this.$router.push({
                  name: "EditAddress",
                  params: { intercomId: this.$route.params.intercomId },
                }),
            },
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Добавить абонентов через файл",
              icon: "upload-file",
              handler: () => {
                this.filePopup.opened = true;
              },
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить домофон",
              icon: "trash",
              handler: () => this.deleteIntercom(this.$route.params.intercomId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Верифицировать абонентов",
              icon: "check",
              handler: () => this.verifySelectedContacts(),
            },
          },
          {
            id: 4,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить строку",
              icon: "trash",
              handler: () => this.deleteContacts(),
            },
          },
          {
            id: 5,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Изменить протокол соединения",
              icon: "check",
              handler: () => this.updateContactsNotificationStatus(),
            },
          },
        ],
      };
    },
    currentAddress() {
      return this.currentIntercom && this.currentIntercom.property ? this.currentIntercom.property.address : null;
    },
    currentServer() {
      return this.currentIntercom && this.currentIntercom.sip_server ? this.currentIntercom.sip_server : null;
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },

    onSwitcherClick({ row, column, currentValue }) {
      if (column === 'isNotifiable') {
        this.updateContactNotificationStatus(row);
      } else {
        this.verifyContact(row);
      }
    },

    onRowClick(id) {
      const client = this.contactList.find((item) => item.uid === id);
      if (!client) return;

      const { extension, password } = client;
      this.clientPopup.value = { id, extension, password };
      this.clientPopup.opened = true;
    },
    onClientPopupClose() {
      this.clientPopup.opened = false;
    },
    onClientPopupApprove() {
      this.contactService.updateContact(this.clientPopup.value).then(response => {
        this.$store.dispatch("setNotification", { text: "Запись успешно обновлена" });
        this.getCurrentInetrcomContacts();

        this.clientPopup.opened = false;
      }).catch((error) => {
        console.log(error)
      })
    },
    onFilePopupClose() {
      this.filePopup.opened = false;
    },
    onFilePopupApprove() {
      this.intercomService.addContacts(this.currentIntercom.cuid, {
        contactsFile: this.filePopup.file
      }).then(response => {
        this.getCurrentInetrcomContacts();

        this.filePopup.opened = false;
      }).catch(error => {
        console.log(error)
      })
      
    },
    verifySelectedContacts() {
      let data = {contacts: []}
      this.rowsSelected.forEach(value => {
        data.contacts.push({id: value})
      })
      this.contactService.verifyContacts(data).then(response => {
        this.rowsSelected = [];
        this.getCurrentInetrcomContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    verifyContact(contactId) {
      this.contactService.verifyContacts({contacts: [{id: contactId}]}).then(response => {
        this.getCurrentInetrcomContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    deleteIntercom(intercomId) {
      this.intercomService.removeIntercoms([intercomId]).then(response => {
          this.getCurrentInetrcomContacts();
          this.$router.push({name: 'Intercom'})
      }).catch((error) => {
        console.log(error)
      })
    },
    deleteContacts() {
      this.contactService.removeContacts(this.rowsSelected).then(response => {
        this.getCurrentInetrcomContacts();
      }).catch(error => {
        console.log(error)
      })
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sipServerContactConfigId = this.$route.params.intercomId;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    getCurrentInetrcomContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sipServerContactConfigId = this.$route.params.intercomId;

      this.getFilteredContacts();
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page,
        this.contactsMeta.total,
        this.contactsMeta.per_page
      );
    },
    getFilteredContacts() {
      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },

    updateContactNotificationStatus(contactId) {
      this.contactService.updateContactsNotificationStatus({contacts: [{id: contactId}]}).then(response => {
        this.getCurrentInetrcomContacts();
      }).catch(error => {
        console.log(error);
      })
    },

    updateContactsNotificationStatus() {
      let data = {contacts: []}

      this.rowsSelected.forEach(value => {
        data.contacts.push({id: value});
      });

      this.contactService.updateContactsNotificationStatus(data).then(response => {
        this.rowsSelected = [];
        this.getCurrentInetrcomContacts();
      }).catch(error => {
        console.log(error);
      });
    }
  },

  async mounted() {
    await this.intercomService.getIntercom(this.$route.params.intercomId).then(response => {
      if (response.data) {
          this.currentIntercom = response.data
      }
    }).catch((error) => {
        console.log(error);
    })

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    this.getCurrentInetrcomContacts();
  },
};
