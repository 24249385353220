import httpProvider from 'Providers/http.js';

export default {
    getIntercoms(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/contact-config?' + new URLSearchParams(filters).toString()).then(response => {
                console.log(response)
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getIntercom(intercomId) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/contact-config/show/' + intercomId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    addIntercom(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/contact-config/create', data, {headers: {
                'Content-Type': 'multipart/form-data'
            }}).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    addContacts(intercomId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/contact-config/add-contacts/' + intercomId, data, {headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateIntercom(intercomId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/contact-config/update/' + intercomId, data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    removeIntercoms(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/contact-config/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
}