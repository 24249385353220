// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address[data-v-2a8b6d44] {
  width: 100%;
}
.address__header[data-v-2a8b6d44], .address__filters[data-v-2a8b6d44], .address__back[data-v-2a8b6d44], .address__list[data-v-2a8b6d44] {
  margin: 0px 16px 24px;
}
.address__subheader[data-v-2a8b6d44] {
  margin: 40px 16px 24px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/Server/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADGE;EAIE,qBAAA;ACJJ;ADOE;EACE,sBAAA;ACLJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.address {\n  width: 100%;\n\n  &__header,\n  &__filters,\n  &__back,\n  &__list {\n    margin: 0px $space-md $space-lg;\n  }\n\n  &__subheader {\n    margin: $space-xl $space-md $space-lg;\n  }\n}\n",".address {\n  width: 100%;\n}\n.address__header, .address__filters, .address__back, .address__list {\n  margin: 0px 16px 24px;\n}\n.address__subheader {\n  margin: 40px 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
