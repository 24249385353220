<template>
  <section class="address">
    <Popup
      v-if="filePopup.opened"
      title="Добавить абонентов через файл"
      @close="onFilePopupClose"
      @approve="onFilePopupApprove"
    >
      <p>
        Выгрузить из BasIP файлы с абонентами и загрузите сюда (справка по
        формату файла).
      </p>
      <FormFile v-model="filePopup.file" label="Загрузить файл с абонентами" />
    </Popup>

    <Popup
      v-if="clientPopup.opened"
      title="Редактировать абонента"
      @close="onClientPopupClose"
      @approve="onClientPopupApprove"
    >
      <FormList v-model="clientPopup.value" :list="clientPopup.list" />
    </Popup>

    <Header class="address__header">
      <template v-slot:title>Домофон</template>
    </Header>
    <Header class="address__header" type="h3" spaced>
      <template v-slot:title>{{ address.address }}</template>
      <template v-slot:addons>
        <icon-base icon-name="server" :label="address.ip" />
        <Chips :chips="address.chips" />
      </template>
    </Header>
    <BackButton router-page-name="Intercom" class="address__back">
      Назад к домофонам
    </BackButton>
    <Header class="address__subheader" type="h4">
      <template v-slot:title>
        Абоненты
        <template v-if="address.count">({{ address.count }})</template>
      </template>
    </Header>
    <div class="address__filters">
      <Filters v-model="filtersActive" :filters="filters" />
    </div>
    <div class="address__list">
      <Table
        v-model="rowsSelected"
        :columns="tableColumns"
        :rows="tableRows"
        @switcherClick="onSwitcherClick"
        @rowClick="onRowClick"
      />
      <Pagination v-model="currentPage" v-bind="pagination" @switchPage="onSwitchPage" />
    </div>
    <div class="address__actions">
      <Actions
        :actions="rowsSelected.length ? actions.selected : actions.default"
      />
    </div>
  </section>
</template>

<script>
import Popup from "../../components/Popup.vue";
import Header from "../../components/Header.vue";
import Filters from "../../components/Filters.vue";
import Table from "../../components/Table.vue";
import Actions from "../../components/Actions.vue";
import MockIntercomAddress from "../../mixins/Mock/MockIntercomAddress.js";
import IconBase from "../../components/Icons/IconBase.vue";
import Chips from "../../components/Chips.vue";
import FormFile from "../../components/Form/File.vue";
import FormList from "../../components/Form/List.vue";
import MockPagination from "../../mixins/Mock/MockPagination.js";
import Pagination from "../../components/Pagination.vue";
import BackButton from "../../components/BackButton.vue";

export default {
  name: "AddressList",
  mixins: [MockIntercomAddress, MockPagination],
  components: {
    Popup,
    Header,
    Filters,
    Table,
    Actions,
    IconBase,
    Chips,
    FormFile,
    FormList,
    Pagination,
    BackButton,
  },
  data() {
    return {
      filtersActive: {},
      rowsSelected: [],
    };
  },
  watch: {
    filtersActive: {
      handler(value) {
        if (value.status === null) {
          value.status = '';
        }

        this.filterContacts(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  width: 100%;

  &__header,
  &__filters,
  &__back,
  &__list {
    margin: 0px $space-md $space-lg;
  }

  &__subheader {
    margin: $space-xl $space-md $space-lg;
  }
}
</style>
