<template>
  <section class="chips">
    <div
      v-for="(chip, index) in chips"
      :key="chip.id || index"
      :class="`chips__chip chips__chip_${chip.type}`"
    >
      <icon-base
        v-if="chip.icon"
        :icon-name="chip.icon"
        width="14"
        height="14"
      />
      {{ chip.label }}
    </div>
  </section>
</template>

<script>
import IconBase from "../components/Icons/IconBase.vue";

export default {
  name: "Chips",
  components: { IconBase },
  props: {
    chips: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $space-xs;

  &__chip {
    display: flex;
    align-items: center;
    gap: $space-xxxxs;
    border-radius: $chip-border-radius;
    padding: $chip-padding;
    color: $color-white;
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-weight-lg;

    &_complited {
      background-color: $color-green;
    }
  }
}
</style>
