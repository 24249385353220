// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add[data-v-45f6875b] {
  width: 100%;
}
.add__header[data-v-45f6875b] {
  margin: 0px 16px 44px;
}
.add__message[data-v-45f6875b] {
  margin: 0px 16px 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/AddIntercom/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADGE;EACE,qBAAA;ACDJ;ADIE;EACE,qBAAA;ACFJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.add {\n  width: 100%;\n\n  &__header {\n    margin: 0px $space-md $space-xxl;\n  }\n\n  &__message {\n    margin: 0px $space-md $space-md;\n  }\n}\n",".add {\n  width: 100%;\n}\n.add__header {\n  margin: 0px 16px 44px;\n}\n.add__message {\n  margin: 0px 16px 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
