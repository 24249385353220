import types from "../../config/types.js";
import ServerService from "Services/ServerService.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [ServiceData, MockPagination],
  data() {
    return {
      serverPopup: {
        mode: types.serverModes.add,
        modelValue: {},
        opened: false,
      },
      serverService: ServerService
    };
  },
  computed: {
    server() {
      return {
        name: "hostmyhostingasap.com",
        // nat: "bool",
        dtmfmode: "enum",
        transport: "enum:udp|tcp|tls",
        type: "enum:peer|friend|,,,",
        // quality: "auto|int",
        // fromdomain: "string",
      };
    },
  },
  methods: {
    onDeleteServer(serverId) {
      this.serverService.removeServers([serverId]).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.$router.push({name: 'Intercom'})
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        this.$store.dispatch("setNotification", { text: "Сперва удалите домофон сервера" });
        this.serverPopup.opened = false;
      });
    },
    onAddServer() {
      this.serverPopup.mode = types.serverModes.add;
      this.serverPopup.modelValue = {};
      this.serverPopup.opened = true;
    },
    onEditServer() {
      this.serverPopup.mode = types.serverModes.edit;
      this.serverPopup.modelValue = { ...this.server };
      this.serverPopup.opened = true;
    },
    onServerPopupClose() {
      this.serverPopup.opened = false;
    },
    onServerPopupApprove() {
      if (!this.validate()) {
        this.$store.dispatch("setNotification", { text: "Заполните все поля", type: "cross-outlined" });
        return
      }

      this.serverService.addServer({
        ouid: this.currentOrganizationId,
        host: this.serverPopup.modelValue.name,
        // fromdomain: this.serverPopup.modelValue.fromdomain,
        nat: this.serverPopup.modelValue.nat,
        dtmfmode: this.serverPopup.modelValue.dtmfmode,
        // quality: this.serverPopup.modelValue.quality,
        transport: this.serverPopup.modelValue.transport,
        type: this.serverPopup.modelValue.type
      }).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.initPagination(
            this.serversMeta.current_page,
            this.serversMeta.total,
            this.serversMeta.per_page
          );
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        console.log(error)
        this.serverPopup.opened = false;
      });
    },
    validate() {
      let data = this.serverPopup.modelValue;

      return data.name && data.nat && data.dtmfmode && data.transport && data.type
    }
  },
};
