// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intercom[data-v-25df5126] {
  width: 100%;
}
.intercom__header[data-v-25df5126] {
  margin: 0px 16px 44px;
}
.intercom__tabs[data-v-25df5126] {
  margin: 0px 16px 40px;
}
.intercom__content[data-v-25df5126] {
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/Intercom/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADGE;EACE,qBAAA;ACDJ;ADIE;EACE,qBAAA;ACFJ;ADKE;EACE,WAAA;ACHJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.intercom {\n  width: 100%;\n\n  &__header {\n    margin: 0px $space-md $space-xxl;\n  }\n\n  &__tabs {\n    margin: 0px $space-md $space-xl;\n  }\n\n  &__content {\n    margin: 0px;\n  }\n}\n",".intercom {\n  width: 100%;\n}\n.intercom__header {\n  margin: 0px 16px 44px;\n}\n.intercom__tabs {\n  margin: 0px 16px 40px;\n}\n.intercom__content {\n  margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
