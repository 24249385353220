import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      filtersData: {}
    }
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 5,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "property_ids",
          type: types.components.select,
          width: 4,
          attr: {
            placeholder: "Все адреса",
            options: this.propertyListOptions,
          },
        },
        {
          id: "sip_server_ids",
          type: types.components.select,
          width: 3,
          attr: {
            placeholder: "Все серверы",
            options: this.serverListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
          onclick: () => {
            this.filtersData.order = 'address';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredIntercoms();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'address'
        },
        {
          id: "server",
          label: "Сервер",
          onclick: () => {
            this.filtersData.order = 'host';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredIntercoms();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'host'
        },
        {
          id: "subs",
          label: "Количество абонентов",
          onclick: () => {
            this.filtersData.order = 'contact_count';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredIntercoms();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'contact_count'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.intercomList.forEach((intercom) => {
        data.push({
          id: intercom.cuid,
          columns: [
            {
              id: "address",
              content: {
                id: intercom.cuid,
                label: intercom.property.address,
              },
            },
            {
              id: "server",
              content: {
                id: intercom.cuid,
                label:
                  intercom.sip_server.host ?? "-",
              },
            },
            {
              id: "subs",
              content: {
                id: intercom.cuid,
                label: intercom.user_contact_bindings_count,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Добавить сервер",
              icon: "plus-outlined",
              handler: () => this.$emit("addServer"),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Настроить домофонию",
              icon: "plus-outlined",
              handler: () => this.$router.push({ name: "AddIntercom" }),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить домофоны",
              icon: "trash",
              handler: () => this.removeIntercoms(),
            },
          },
        ],
      };
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onRowClick(rowId) {
      this.$router.push({ name: "Address", params: { intercomId: rowId } });
    },
    filterIntercom(filters) {
      this.filtersData = filters;

      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        this.getFilteredIntercoms();
      }, 2000);
    },
    setIntercomsPagination() {
      this.initPagination(
        this.intercomsMeta.current_page,
        this.intercomsMeta.total,
        this.intercomsMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredIntercoms();
    },
    getFilteredIntercoms() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.getIntercoms(this.filtersData);
      this.setIntercomsPagination();
    },
    removeIntercoms() {
      this.intercomService.removeIntercoms(this.rowsSelected).then(response => {
        this.$store.dispatch("setNotification", { text: "Домофоны успешно удалены." });
        this.getFilteredIntercoms()
        this.rowsSelected = [];
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.intercomList.length === 0) {
      this.getIntercoms({
        organizationId: this.currentOrganizationId
      });
    }

    this.setIntercomsPagination();
  },
};
