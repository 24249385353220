import Intercom from "Pages/Intercom/Index.vue";
import AddIntercom from "Pages/AddIntercom/Index.vue";
import Contacts from "Pages/AddIntercom/Contacts.vue";
import Components from "Pages/Components.vue";
import Address from "Pages/Address/Index.vue";
import EditAddress from "Pages/Address/Edit.vue";
import Server from "Pages/Server/Index.vue";
import MobileAuth from "Pages/MobileAuth.vue";
import Auth from "Pages/Auth.vue";
import Oidc from "Pages/Oidc.vue";

import auth from "Middleware/auth.js";
import guest from "Middleware/guest.js";

export default [
  {
    path: "/oidc/callback",
    name: "Oidc",
    component: Oidc,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/",
    name: "Auth",
    component: Auth,
    meta: {
      // middleware: [guest]
    }
  },
  {
    path: "/auth/mobile",
    name: "MobileAuth",
    component: MobileAuth,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/intercom",
    name: "Intercom",
    component: Intercom,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/intercom/add",
    name: "AddIntercom",
    component: AddIntercom,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/components",
    name: "Components",
    component: Components,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/address/:intercomId",
    name: "Address",
    component: Address,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/editAddress/:intercomId",
    name: "EditAddress",
    component: EditAddress,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/server/:serverId",
    name: "Server",
    component: Server,
    meta: {
      middleware: [auth]
    }
  },
];
