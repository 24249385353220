import httpProvider from 'Providers/http.js';

export default {
    getServers(filters) {
        console.log('?' + new URLSearchParams(filters).toString())
        return new Promise((resolve, reject) => {
            httpProvider.get('sip-server?' + new URLSearchParams(filters).toString()).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    addServer(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/sip-server/create', data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    updateServer(serverId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/sip-server/update/' + serverId, data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    removeServers(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/sip-server/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}