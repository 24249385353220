// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-list__filters[data-v-8197a946] {
  margin: 0px 16px 24px;
}
.address-list__list[data-v-8197a946] {
  margin: 0px 16px 24px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/Intercom/ServerList.vue","webpack://./ServerList.vue"],"names":[],"mappings":"AAGE;EACE,qBAAA;ACFJ;ADKE;EACE,qBAAA;ACHJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.address-list {\n  &__filters {\n    margin: 0px $space-md $space-lg;\n  }\n\n  &__list {\n    margin: 0px $space-md $space-lg;\n  }\n}\n",".address-list__filters {\n  margin: 0px 16px 24px;\n}\n.address-list__list {\n  margin: 0px 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
