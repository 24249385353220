// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-list__notification[data-v-3d2b78a7], .address-list__title[data-v-3d2b78a7], .address-list__filters[data-v-3d2b78a7], .address-list__list[data-v-3d2b78a7] {
  margin: 0px 16px 24px;
}`, "",{"version":3,"sources":["webpack://./src/assets/js/pages/AddIntercom/ContactList.vue","webpack://./ContactList.vue"],"names":[],"mappings":"AAGE;EAIE,qBAAA;ACLJ","sourcesContent":["@import \"./src/assets/sass/_vars.scss\";\n\n.address-list {\n  &__notification,\n  &__title,\n  &__filters,\n  &__list {\n    margin: 0px $space-md $space-lg;\n  }\n}\n",".address-list__notification, .address-list__title, .address-list__filters, .address-list__list {\n  margin: 0px 16px 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
