import { createStore } from "vuex";

import auth from "Store/modules/auth.js";
import intercom from "Store/modules/intercom.js";
import server from "Store/modules/server.js";
import property from "Store/modules/property.js";
import contact from "Store/modules/contact.js";
import notifications from "Store/modules/notifications.js";

export default createStore({
  modules: {
    auth,
    intercom,
    server,
    property,
    contact,
    notifications,
  },
});
