import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      actionValues: {3: false},
      filtersData: {}
    };
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "propertyIds",
          type: types.components.select,
          width: 5,
          attr: {
            placeholder: "Все адреса",
            options: this.propertyListOptions,
          },
        },
        {
          id: "status",
          type: types.components.select,
          width: 3,
          attr: {
            placeholder: "Статус верификации",
            options: [
              {
                id: true,
                label: "Верифицирован",
              },
              {
                id: false,
                label: "Не верифицирован",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
        },
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "unit_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "sip_id",
          label: "Extension",
          onclick: () => {
            this.filtersData.order = 'extension';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension'
        },
        {
          id: "sip_id_udp",
          label: "Extension UDP",
          onclick: () => {
            this.filtersData.order = 'extension_udp';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'extension_udp'
        },
        {
          id: "password",
          label: "Пароль",
        },
        {
          id: "phone",
          label: "Телефон (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'phone';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'phone'
        },
        {
          id: "name",
          label: "ФИО (из контактов CRM)",
          onclick: () => {
            this.filtersData.order = 'name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'name'
        },
        {
          id: "status",
          component: {
            type: "switcher",
            "label-true": "Верифицирован",
            "label-false": "Не верифицирован",
          },
          label: "Статус верификации",
          onclick: () => {
            this.filtersData.order = 'status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'status'
        },
      ];
    },
    tableRows() {
      const data = [];

      this.contactList.forEach((contact) => {
        data.push({
          id: contact.uid,
          columns: [
            {
              id: "address",
              content: {
                id: contact.uid,
                label: contact.sip_server_contact_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: contact.uid,
                label: contact.unit_name,
              },
            },
            {
              id: "unit_type",
              content: {
                id: contact.uid,
                label: contact.unit_type && contact.unit_type == 'flat' ? 'Квартира' : 'Апартаменты',
              },
            },
            {
              id: "sip_id",
              content: {
                id: contact.uid,
                label: contact.extension,
              },
            },
            {
              id: "sip_id_udp",
              content: {
                id: contact.uid,
                label: contact.extension_udp,
              },
            },
            {
              id: "password",
              content: {
                id: contact.uid,
                label: contact.password,
              },
            },
            {
              id: "name",
              content: {
                id: contact.uid,
                label: contact.user?.name,
              },
            },
            {
              id: "phone",
              content: {
                id: contact.uid,
                label: contact.user?.phone,
              },
            },
            {
              id: "status",
              content: {
                id: contact.uid,
                label: contact.is_verified,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Сохранить",
              disabled: !this.actionValues['3'],
              handler: () => this.onSaveContacts(),
            },
          },
          {
            id: 3,
            type: types.actions.checkbox,
            attr: {
              label:
                "Подтверждаю, что данные верны, готов опубликовать их в МП жителя",
              handler: () => this.actionValues['3'] = !this.actionValues['3'],
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Верифицировать абонентов",
              icon: "check",
              handler: () => this.verifySelectedContacts(),
            },
          },
        ],
      };
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onSaveContacts() {
      this.$store.dispatch("deleteTempIntercomIds");
      this.$store.dispatch("setNotification", { text: "Домофоны добавлены" });
      this.$router.push({ name: "Intercom" });
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row);
    },
    onRowClick(rowId) {
      console.log(rowId);
    },
    verifySelectedContacts() {
      let data = { contacts: [] };
      this.rowsSelected.forEach((value) => {
        data.contacts.push({ id: value });
      });

      this.contactService
        .verifyContacts(data)
        .then((response) => {
          this.rowsSelected = [];
          this.getFilteredContacts();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verifyContact(contactId) {
      this.contactService
        .verifyContacts({ contacts: [{ id: contactId }] })
        .then((response) => {
          this.getFilteredContacts();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.sipServerContactConfigIds = this.$store.getters.tempIntercomIds;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page ?? 1,
        this.contactsMeta.total ?? 1,
        this.contactsMeta.per_page ?? 1
      );
    },
    getFilteredContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.sipServerContactConfigIds = this.$store.getters.tempIntercomIds;
      this.getContacts(this.filtersData).then(response => {
        this.setContactsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
  },
  mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      sipServerContactConfigIds: this.$store.getters.tempIntercomIds,
    });

    this.getContacts({
      organizationId: this.currentOrganizationId,
      sipServerContactConfigIds: this.$store.getters.tempIntercomIds,
    }).then(response => {
      this.setContactsPagination();
    });
  },
};
