<template>
    <div class="content pad-t-20 pad-b-20">
        {{ message }}
    </div>
</template>

<script>

import AuthService from '../services/AuthService.js';
import ErrorMessages from '../providers/ErrorMessages.js';

export default {
    name: 'Auth',
    data() {
        return {
            message: null,
            authService: AuthService,
            errorMessages: ErrorMessages
        }
    },
    mounted() {
        this.authenticate();
    },
    methods: {
        authenticate() {
            this.message = this.errorMessages.signingIn;
            const urlParams = new URLSearchParams(window.location.search);

            if (!urlParams.has('organizationId') || !urlParams.has('organizationGuid') || !urlParams.has('userGuid')) {
                this.message = this.errorMessages.missingRequiredCondoParams;
                return false;
            }
            
            if (urlParams.has('authData')) {
                let authData = JSON.parse(urlParams.get('authData'));
                this.$store.dispatch('setAccessToken', authData.access_token);
                this.$store.dispatch('setRefreshToken', authData.refresh_token);
            }

            this.$store.dispatch('setCurrentOrganizationId', urlParams.get('organizationId'));
            this.$store.dispatch('setCondoUserId', urlParams.get('userGuid'));
            this.$store.dispatch('setCondoOrganizationId', urlParams.get('organizationGuid'));

            this.$router.push({name: 'Intercom'});
        }
    }
}

</script>


<style scoped lang="scss">



</style>